:root {
  /* --green-PA: #8acb3f; */
  --green-PA: #01aef2;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.truncateOneLineText {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
}

.full-page {
  min-height: calc(100vh - 100px);
}

.row {
  --bs-gutter-x: 0 !important;
}

.bg-green {
  background-color: var(--green-PA) !important;
}
.text-green {
  color: var(--green-PA);
}

/* Carrusel */
.carousel-control-next-icon, .carousel-control-prev-icon {
  background-color: #f24400;
  border-radius: 50%;
  width: 3rem !important;
  height: 3rem !important;
}